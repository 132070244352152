import React from "react"
import {Col, Modal, Row} from "reactstrap"
import TamashaButton from "./TamashaButton"
import ProgressView from "./ProgressView"
import FilePicker from "./FilePicker";
import {useFiles} from "../hooks/useFiles";
import {FormAlert} from "./FormAlert";

const NewFileModal = ({open, setOpen, onSuccess, projectId}) => {
    const {
        setPhoto,
        createFile,
        loading,
        loadingStates,
        message,
        error
    } = useFiles()

    const toggle = () => setOpen(!open)

    const handleUpload = () => {
        createFile(projectId,() => {
            setOpen(false)
            onSuccess?.()
        })
    }

    return <Modal
        isOpen={open}
        toggle={toggle}
    >
        <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
                Upload new file
            </h5>
            <button
                type="button"
                onClick={() => {
                    toggle()
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            <Row>
                <Col md={12}>
                    <FormAlert message={message} error={error}/>

                    <FilePicker
                        label={"Select a file"}
                        onChange={e => setPhoto(e.target.files[0])}
                    />
                </Col>
            </Row>
        </div>
        <div className="modal-footer">
            <div>
                {loading[loadingStates.createFile] ? <ProgressView/> :
                    <TamashaButton onClick={handleUpload}>Upload</TamashaButton>}
            </div>
        </div>
    </Modal>
}

export default NewFileModal
