import {useState} from "react";
import {fetchData, postData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";
import {ERROR, Notify, SUCCESS} from "../util/Notify";

const loadingStates = {
    projects: 'projects',
    findProject: 'findProject',
    createProject: 'createProject',
    updateProject: 'updateProject',
    deleteProject: 'deleteProject',
}

export const useProjects = () => {
    const [projects, setProjects] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const [name, setName] = useState('')
    const [category, setCategory] = useState('')
    const [subCategory, setSubCategory] = useState('')
    const [group, setGroup] = useState('')
    const [ownerID, setOwnerID] = useState([])
    const [photo, setPhoto] = useState('')
    const [description, setDescription] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [videoUrl, setVideoUrl] = useState('')
    const [region, setRegion] = useState('Europe')
    const [material, setMaterial] = useState([])
    const [manufacturer, setManufacturer] = useState([])
    const [year, setYear] = useState('')
    const [area, setArea] = useState('')
    const [tags, setTags] = useState('')
    const [color, setColor] = useState('')
    const [entityType, setEntityType] = useState('project')
    const [propertyId, setPropertyId] = useState('')
    const [use, setUse] = useState('')
    const [articleType, setArticleType] = useState('')

    const [project, setProject] = useState('')

    const [createAnother, setCreateAnother] = useState(false)

    const loadProjects = ({
                              offset,
                              query,
                              records = Strings.RECORDS_PER_PAGE,
                              projectOwnerId = '',
                              categoryId = "",
                              groupId = "",
                              subCategoryId = "",
                              propertyId = "",
                              entityType = ""
                          }) => {
        setIsLoading(loadingState(loading, loadingStates.projects, true))

        fetchData(Endpoints.PROJECTS({
            offset,
            query,
            records,
            projectOwnerId,
            categoryId,
            groupId,
            subCategoryId,
            propertyId,
            entityType
        }))
            .then(res => res.json())
            .then(res => {
                setProjects(res.data || [])
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.projects, false)))
    }

    const findProject = (id) => {
        setIsLoading(loadingState(loading, loadingStates.findProject, true))

        fetchData(Endpoints.PROJECT_WITH_ID(id))
            .then(res => res.json())
            .then(res => {
                setProject(res.data)
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.findProject, false)))
    }

    const createProject = () => {
        setError('')
        setMessage('')

        if (!name || !description || !description || !photo) {
            Notify('All fields are required', ERROR)
            return
        }

        setIsLoading(loadingState(loading, loadingStates.createProject, true))

        const data = new FormData()
        data.append("file", photo)
        data.append("name", name)
        data.append("description", description.toString('html'))
        data.append("category_id", category)
        data.append("group_id", group)
        data.append("sub_category_id", subCategory)
        data.append("owner_id", ownerID.toString())
        data.append("start_date", startDate)
        data.append("end_date", endDate)
        data.append("video_url", videoUrl)
        data.append("region", region)
        data.append("year", year)
        data.append("area", area)
        data.append("tags", tags)
        data.append("color", color)
        data.append("manufacturer", manufacturer.toString())
        data.append("materials", material.toString())
        data.append("entity_type", entityType)
        data.append("property_id", propertyId)
        data.append("use", use)
        data.append("article_type", articleType)

        postData(Endpoints.PROJECT, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    Notify(res.errors?.[0] || res.message, ERROR)
                    return;
                }

                Notify(res.data, SUCCESS)

                setName('')

                setTimeout(() => {
                    setMessage('')
                    setError('')
                    !createAnother && window.location.assign(entityType === "project" ? "/dashboard/projects" : "/dashboard/products")
                }, 1500)


            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.createProject, false)))
    }

    const updateProjectDetails = (id) => {
        setError('')
        setMessage('')

        if (!name) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateProject, true))

        const data = new FormData()
        data.append("id", id)
        data.append("name", name)
        data.append("description", description)
        data.append("category_id", category)
        data.append("owner_id", ownerID)
        data.append("deadline", startDate)
        data.append("video_url", videoUrl)
        data.append("region", region)
        data.append("group_id", group)

        postData(Endpoints.UPDATE_PROJECT_DETAILS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                findProject(id)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateProject, false)))
    }

    const updateProjectStatus = (id, verified) => {
        setError('')
        setMessage('')


        setIsLoading(loadingState(loading, loadingStates.updateProject, true))

        const data = new FormData()
        data.append("id", id)
        data.append("verified", verified)

        postData(Endpoints.UPDATE_PROJECT_STATUS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                findProject(id)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateProject, false)))
    }

    const openOrCloseProject = (id, status) => {
        setError('')
        setMessage('')

        setIsLoading(loadingState(loading, loadingStates.updateProject, true))

        const data = new FormData()
        data.append("id", id)
        data.append("status", status)

        postData(Endpoints.UPDATE_PROJECT_OPEN_STATUS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                findProject(id)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateProject, false)))
    }

    const updateProjectCover = (id) => {
        setError('')
        setMessage('')

        if (!photo) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateProject, true))

        const data = new FormData()
        data.append("id", id)
        data.append("file", photo)

        postData(Endpoints.UPDATE_PROJECT_PHOTO, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                findProject(id)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateProject, false)))
    }

    const deleteProject = (id) => {
        setError('')
        setMessage('')


        setIsLoading(loadingState(loading, loadingStates.updateProject, true))

        const data = new FormData()
        data.append("id", id)

        postData(Endpoints.DELETE_PROJECT, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    window.location.assign("/dashboard/projects")
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateProject, false)))
    }

    return {
        projects,
        loading,
        error,
        loadProjects,
        createProject,
        createAnother,
        setCreateAnother,
        message,
        setMessage,
        loadingStates,
        name,
        setName,
        photo,
        setPhoto,
        updateProjectCover,
        updateProjectDetails,
        findProject,
        updateProjectStatus,
        deleteProject,
        project,
        startDate,
        setStartDate,
        description,
        setDescription,
        category,
        setCategory,
        ownerID,
        setOwnerID,
        videoUrl,
        setVideoUrl,
        region,
        setRegion,
        group,
        setGroup,
        openOrCloseProject,
        subCategory,
        setSubCategory,
        material,
        setMaterial,
        manufacturer,
        setManufacturer,
        tags,
        setTags,
        year,
        setYear,
        area,
        setArea,
        color,
        setColor,
        endDate,
        setEndDate,
        propertyId,
        setPropertyId,
        entityType,
        setEntityType,
        articleType,
        setArticleType,
        use,
        setUse
    }

}
