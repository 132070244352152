import {useGallerys} from "../../../hooks/useGallery";
import React, {useCallback, useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import {getFileUrl} from "../../../util/Server";
import NewGalleryModal from "../../../components/NewGalleryModal";
import TamashaButton from "../../../components/TamashaButton";

export const Gallery = ({project}) => {

    const {loadGallerys, gallerys} = useGallerys()

    const [open, setOpen] = useState()

    const loadGallery = useCallback(() => {
        if (!project?.id) return

        loadGallerys({project_id: project?.id})
    }, [project])

    useEffect(() => {
        loadGallery()
    }, [loadGallery])

    return (
        <>
            <NewGalleryModal open={open} setOpen={setOpen} onSuccess={loadGallery}/>

            <div className="text-end mb-4">
                <TamashaButton onClick={() => {
                    setOpen(true)
                }} outline>
                    Add a photo
                </TamashaButton>
            </div>

            <Row>
                {gallerys?.rows?.map(photo => (
                    <Col md={4}>
                        <img src={getFileUrl(photo?.link)} style={{width: 'auto', maxWidth: '100%', height: 'auto'}}/>
                    </Col>
                ))}
            </Row>
        </>
    )
}
