import React, {useEffect, useState} from "react"

import {Card, CardBody, Col, Container, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import classnames from "classnames";
import ProgressView from "../../components/ProgressView";
import TamashaButton from "../../components/TamashaButton";
import TextField from "../../components/TextField";
import {FormAlert} from "../../components/FormAlert";
import {PageLoader} from "../../components/PageLoader";
import {useProjects} from "../../hooks/useProjects";
import {DetailsTab} from "./components/DetailsTab";
import PortfolioTable from "../../components/Tables/PortfolioTable";
import SelectView from "../../components/SelectView";
import DatePicker from "../../components/DatePicker";
import {useArchitects} from "../../hooks/useArchitects";
import {useCategories} from "../../hooks/useCategories";
import FilePicker from "../../components/FilePicker";
import ImpactRoadmapTable from "../../components/Tables/ImpactRoadmapTable";
import {useGroups} from "../../hooks/useGroups";
import TransactionsTable from "../../components/Tables/TransactionsTable";
import {DeleteButton} from "../../components/DeleteButton";
import {ProjectForm} from "./components/ProjectForm";
import {Gallery} from "./components/Gallery";
import {Files} from "./components/Files";

const styles = {
    tabStyle: {
        cursor: "pointer"
    }
}


const ProjectDetail = ({match, location, ...rest}) => {

    const [activeTab, setActiveTab] = useState(0)
    const toggle = tab => setActiveTab(tab)

    const {id} = match.params
    const {project: projectFromParams} = location.state

    const {
        loading,
        error,
        message,
        loadingStates,
        setPhoto,
        updateProjectDetails,
        updateProjectCover,
        updateProjectStatus,
        findProject,
        project,
        openOrCloseProject,
        deleteProject,
    } = useProjects()

    useEffect(() => {
        findProject(id)
    }, [id])

    const isProject = project.entity_type === "project"


    return (
        <React.Fragment>
            {project ?
                <div className="page-content">
                    <MetaTitle title={project.name}/>
                    <Container fluid={true}>
                        <Breadcrumbs title="Dashboard" breadcrumbItem={project.name} {...rest}/>

                        <Card>
                            <CardBody>

                                <div className={"mb-2 mt-2 d-flex justify-content-end"}>
                                    {loading[loadingStates.updateProject] && <ProgressView/>}

                                    {!loading[loadingStates.updateProject] &&
                                    <>
                                        <TamashaButton
                                            extraClassName={`${project?.verified ? "btn-danger" : "btn-success"} mb-2 me-3`}
                                            onClick={() => {
                                                updateProjectStatus(project?.id, project.verified ? 0 : 1)
                                            }}>
                                            {project.verified ? "Unverify" : "Verify"}
                                        </TamashaButton>


                                        {/*<TamashaButton*/}
                                        {/*    extraClassName={`${project?.status === "ACTIVE" ? "btn-danger" : "btn-success"} mb-2 me-3`}*/}
                                        {/*    onClick={() => {*/}
                                        {/*        openOrCloseProject(project?.id, project?.status === "ACTIVE" ? "CLOSED" : "ACTIVE")*/}
                                        {/*    }}>*/}
                                        {/*    {project?.status === "ACTIVE" ? "Close project" : "Open project"}*/}
                                        {/*</TamashaButton>*/}
                                    </>
                                    }

                                    {loading[loadingStates.deleteProject] ? <ProgressView/> :
                                        <DeleteButton onDelete={() => deleteProject(project?.id)}/>
                                    }
                                </div>

                                <FormAlert message={message} error={error}/>

                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 0,
                                            })}
                                            onClick={() => toggle(0)}
                                        >
                                            Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 2,
                                            })}
                                            onClick={() => toggle(2)}
                                        >
                                            Update Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 4,
                                            })}
                                            onClick={() => toggle(4)}
                                        >
                                            Update Cover Photo
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 5,
                                            })}
                                            onClick={() => toggle(5)}
                                        >
                                            Gallery
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 6,
                                            })}
                                            onClick={() => toggle(6)}
                                        >
                                            Files
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId={0}>
                                        <DetailsTab project={project}/>
                                    </TabPane>
                                    <TabPane tabId={1}>
                                        <PortfolioTable projectID={project?.id}/>
                                    </TabPane>

                                    <TabPane tabId={2}>
                                        <ProjectForm/>
                                    </TabPane>

                                    <TabPane tabId={5}>
                                        <Gallery project={project}/>
                                    </TabPane>

                                     <TabPane tabId={6}>
                                        <Files project={project}/>
                                    </TabPane>

                                    <TabPane tabId={4}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <FilePicker
                                                    label={"Cover photo"}
                                                    onChange={e => setPhoto(e.target.files[0])}
                                                />
                                            </Col>
                                        </Row>

                                        <div className={"float-end justify-content-end"}>
                                            {loading[loadingStates.updateProject] ? <ProgressView/> :
                                                <TamashaButton
                                                    extraClassName={"float-end"}
                                                    onClick={() => {
                                                        updateProjectCover(project?.id)
                                                    }}>Update Cover</TamashaButton>}
                                        </div>
                                    </TabPane>
                                </TabContent>

                            </CardBody>
                        </Card>

                    </Container>
                </div>
                : <PageLoader/>
            }

        </React.Fragment>
    )
}

export default ProjectDetail
