import {useFiles} from "../../../hooks/useFiles";
import React, {useCallback, useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import NewFileModal from "../../../components/NewFileModal";
import TamashaButton from "../../../components/TamashaButton";

export const Files = ({project}) => {

    const {loadFiles, files} = useFiles()

    const [open, setOpen] = useState()

    const loadFile = useCallback(() => {
        if (!project?.id) return

        loadFiles({project_id: project?.id})
    }, [project])

    useEffect(() => {
        loadFile()
    }, [loadFile])

    return (
        <>
            <NewFileModal open={open} setOpen={setOpen} onSuccess={loadFile} projectId={project?.id}/>

            <div className="text-end mb-4">
                <TamashaButton onClick={() => {
                    setOpen(true)
                }} outline>
                    Add a file
                </TamashaButton>
            </div>

            <Row>
                {files?.rows?.map(file => (
                    <Col md={4}>
                        <a href={file.link}>Download file</a>
                    </Col>
                ))}
            </Row>
        </>
    )
}
